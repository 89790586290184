const React = require("react")

const PageViewEvent = require("./src/components/pageViewEvent")

exports.wrapPageElement = ({ element }) => (
  <React.Fragment>
    <PageViewEvent.default />
    {element}
  </React.Fragment>
)
