import React from "react"
import PropTypes from "prop-types"

function sendPageView({ location, pageContext }) {
  if (process.env.NODE_ENV !== `production`) {
    return null
  }

  const pathIsExcluded =
    location &&
    typeof window.excludeGtagPaths !== `undefined` &&
    window.excludeGtagPaths.some(rx => rx.test(location.pathname))

  if (pathIsExcluded) {
    return null
  }

  // Make sure react-helmet is done with its changes (https://github.com/gatsbyjs/gatsby/issues/11592)
  const sendPageViewEvent = () => {
    const pagePath = location
      ? location.pathname + location.search + location.hash
      : undefined

    if (location.pathname === "/404") {
      return
    }

    if (typeof window.gtag === `function`) {
      const data = {
        page_path: pagePath,
      }

      // Send article/interview category
      if (pageContext.category) {
        data.category = pageContext.category
      }

      window.gtag(`event`, `page_view`, data)
    }

    // Facebook pixel
    typeof window.fbq === `function` && window.fbq("track", "ViewContent")

    // Twitter pixel
    typeof window.twq === `function` && window.twq("track", "PageView")
  }

  if (`requestAnimationFrame` in window) {
    requestAnimationFrame(() => {
      requestAnimationFrame(sendPageViewEvent)
    })
  } else {
    // Simulate 2 rAF calls
    setTimeout(sendPageViewEvent, 32)
  }
}

function sendGAEvent({ event, value, ...otherOptions }) {
  if (process.env.NODE_ENV !== `production`) {
    return null
  }

  if (typeof window !== "undefined" && window.gtag) {
    window.gtag("event", event, {
      name: value,
      ...otherOptions,
    })
  }
}

function GA(props) {
  let track = () => {}

  if (
    process.env.NODE_ENV === `production` &&
    typeof window !== "undefined" &&
    window.gtag &&
    props.event &&
    props.data
  ) {
    track = () => {
      const option = props.option || {}

      window.gtag("event", props.event, {
        name: props.data,
        ...option,
      })
    }
  }

  return (
    <button className="button-ga" onClick={track}>
      {props.children}
    </button>
  )
}

GA.propsTypes = {
  props: PropTypes.object.isRequired,
}

export { sendPageView, sendGAEvent, GA }
