import React from "react"

import { sendGAEvent } from "gatsby-plugin-analytics"

const PageViewEvent = ({ location }) => {
  React.useEffect(() => {
    if (typeof window !== "undefined") {
      // AdBlock detecting
      const event = !!document.getElementById("xvwmCkrDVHBO")
        ? "AdAllow"
        : "AdBlock"

      sendGAEvent({
        event,
        value: event,
        event_category: "AdBlocking",
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // AdBlock detecting
  if (typeof window !== "undefined") {
    window["dataLayer"] = window["dataLayer"] || []
    window["dataLayer"].push({
      event: document.getElementById("xvwmCkrDVHBO") ? "AdBlock" : "AdAllow",
    })
  }

  return null
}

export default PageViewEvent
